<template>
  <div v-loading="assessLoading">
    <div
      class="profitRateResult"
      v-if="hasVal() && level != 'ad'"
    >
      <el-popover
        placement="top"
        title=""
        width="880"
        trigger="hover"
        @show="setActiveTab"
      >
        <el-tabs
          v-model="activeTab"
          type="border-card"
          @tab-click="getMultiData"
        >
          <el-tab-pane
            name="adProductReport"
            :label="(level == 'campaign' ? '广告系列层级' : '组') + '经营分析'"
          >
            <product-report-card
              :productReport="productReport('adProductReport')"
              :dateRange="dateRange"
              :platform="platform"
            ></product-report-card>
          </el-tab-pane>
          <el-tab-pane
            name="dataPane"
            label="数据看板"
            :disabled="level !== 'campaign'"
          >
            <data-board
              :multiData="multiData"
              ref="dataBoard"
              v-if="activeTab == 'dataPane'"
              v-loading="loading"
            ></data-board>
          </el-tab-pane>
        </el-tabs>
        <div slot="reference">
          <div class="txt_img">
            <span v-if="level == 'campaign'">{{ keyFlagDesc() }}</span>
            <img
              :src="imgSrc[profitRateResult]"
              alt="经营分析"
            />
          </div>
        </div>
      </el-popover>
    </div>
    <div v-else>--</div>
  </div>
</template>

<script>
import productReportCard from '@/views/adManagement/components/table/productReportCard';
import dataBoard from '@/views/adManagement/components/table/dataBoard';
import { ProductReport } from './ProductReport.js';
import HIGHT from '@/assets/icon/level1.png';
import MID from '@/assets/icon/level2.png';
import LOW from '@/assets/icon/level3.png';
import LOW2 from '@/assets/icon/level4.png';
import { multipleAnalysis } from '@/api/adManagement';
export default {
  components: {
    productReportCard,
    dataBoard,
  },
  props: {
    value: {
      type: Object,
    },
    level: {
      type: String,
      default: 'campaign',
    },
    assessLoading: {
      type: Boolean,
      default: () => false,
    },
    platform: {
      type: String,
      default: 'facebook',
    },
    currencyCur: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      activeTab: 'adProductReport',
      imgSrc: {
        HIGHT,
        MID,
        LOW,
        LOW2,
      },
      multiData:{},
      loading:false,
      dateRange:this.platform == 'facebook'?JSON.parse(localStorage.getItem('adDateRange')):JSON.parse(localStorage.getItem('batchAdDateRange'))
    };
  },
  computed: {
    profitRateResult() {
      if (this.hasReports('adProductReport')) return this.productReport('adProductReport').profitRateResult;
      return '';
    },
    paramsData() {
      let info = this.value.row;
      return {
        campaignId: info.campaign_id,
        currency_sign: this.currency,
        platform: this.platform,
        adAccountId: this.accountId,
      };
    },
    currency() {
      return this.currencyCur || this.$store.getters?.currentAccountInfo?.currency.key || 'USD';
    },
    accountId() {
      console.log(this.value.row);
      return this.value.row.adAccountId || this.$store.getters?.currentAccountInfo?.id;
    },
  },
  methods: {
    keyFlagDesc() {
      if (this.hasReports('adProductReport')) return '系列级';
      return '';
    },
    keyFlag() {
      let key = 'adProductReport';
      return key;
    },
    setActiveTab() {
      this.activeTab = this.keyFlag();
      this.dateRange = JSON.parse(localStorage.getItem('adDateRange'))
    },
    // 获取数据看板数据
    getMultiData(v) {
      if (v.name == 'dataPane') {
        this.$nextTick(() => {
          this.$refs.dataBoard.index = 1;
          this.loading = true;
          multipleAnalysis(JSON.stringify(this.paramsData)).then((res) => {
            console.log(res);
            this.loading = false;
            this.multiData = res.data;
          });
        });
      }
    },
    hasReports(key) {
      return Object.keys(this.productReport(key)).length > 0 && !this.productReport(key).nullreason;
    },
    hasVal() {
      return (
        (this.hasReports('adProductReport') &&
          this.productReport('adProductReport').profitRateResult &&
          this.level == 'campaign') ||
        (this.hasReports('adProductReport') &&
          this.productReport('adProductReport').profitRateResult &&
          this.level == 'adset' &&
          this.value.row.local_order?.validOrderCount)
      );
    },
    productReport(key) {
      if (this.value.row.level == 'campaign') {
        return this.value.row[key] || {};
      } else {
        if (this.value.row[key] && Object.keys(this.value.row[key]).length) {
          
          let purchase = this.value.row.local_order?.validOrderCount ?? 0;
          let moneyRate = this.value.row[key].adCost / this.value.row.campaignCost;
          let spend = this.value.row.spend * moneyRate;
          return new ProductReport(
            this.value.row[key],
            // this.value.row.campaignPurchase,
            purchase,
            // this.value.row.campaignCost,
            spend,
          );
        } else {
          return {};
        }
      }
    },
    localOrderReport() {
      if (!this.value.row['adProductReport']) {
        return {};
      }
      let purchase = this.value.row.local_order?.validOrderCount ?? 0;
      let moneyRate = this.value.row['adProductReport'].adCost / this.value.row.campaignCost;
      let spend = this.value.row.spend * moneyRate;
      return new ProductReport(this.value.row['adProductReport'], purchase, spend);
    },
  },
  mounted() {
    this.activeTab = this.keyFlag();
  },
};
</script>

<style lang="scss" scoped>
.txt_img {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 25px;
    height: 25px;
    margin-left: 5px;
  }
}
</style>
